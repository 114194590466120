import React, { useContext, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import './decisionCheck.scss';
import {useNavigate, Link} from "react-router-dom";
import axios from "axios";
import HeaderTitle from "../HeaderTitle/HeaderTitle";
import PilotAreaList from "../PilotAreaList/PilotAreaList";
import ErrorSummary from "../Errors/ErrorSummary";
import ChangePostcode from "../ChangePostcode/ChangePostcode";


// Bring in the API Gateway env variable
const { REACT_APP_GATEWAY_URL } = process.env
interface PadpPostcodeCheckProps {
    updatePadpSelected: () => void;
    updateCspSelected: () => void;
}


const DecisionCheck: React.FC<PadpPostcodeCheckProps> = (props: PadpPostcodeCheckProps) => {


    // this is to help change the page
    const navigate = useNavigate()

    const handleCsp = () => {
        props.updateCspSelected()
        navigate('/cspcheck')
    }

    const handlePadp = () => {
        props.updatePadpSelected()
        navigate('/padpcheck')
    }








    return (
        <>
            <HeaderTitle title={
                <>
                     Choose Benefit Application<br />
                </>
            }/>



            <p>Please select the benefit you are applying for.</p>
            <br />
            <br />
            <br />


            <div className="postcode-form highlighter-rouge" data-form="error-more-detail" >

                <button className="ds_button ds_no-margin--top" onClick={handleCsp} >Carer Support Payment</button>
                <button className="ds_button ds_no-margin--top" onClick={handlePadp} >Pension Age Disability Payment</button>


            </div>
                 
        </>
    );

}

export default DecisionCheck;

